import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Tune,
} from "@mui/icons-material";
import { Button, Slider, ThemeProvider, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import Services from "../../services";
import { Images, Brands } from "../../assets";
import {
  AutocompleteOption,
  ListItemDecorator,
  ListItemContent,
  Drawer as JoyDrawer,
  Autocomplete,
} from "@mui/joy";

type filterType = {
  year: {
    open: boolean;
    value: number[];
  };
  transmation: {
    open: boolean;
    value: string;
  };
  bodyStyle: {
    open: boolean;
    value: string;
  };
  make: {
    open: boolean;
    value: string;
  };
  model: {
    open: boolean;
    value: string;
  };
};

interface FilterProps {
  filters: filterType;
  setFilters: Dispatch<SetStateAction<filterType>>;
  applyFilters: () => void;
}

function valuetext(value: number) {
  return `${value}`;
}

interface PouplarBrandsProps {
  image: string;
  name: string;
  id: number;
}

const FiltersComponent = ({
  filters,
  setFilters,
  applyFilters,
}: FilterProps) => {
  const openSide = "right";
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const minDistance = 2;
  const [open, setOpen] = useState<boolean>(false);
  const [years, setYears] = useState<number[]>([]);
  const [bodyStyles, setBodyStyles] = useState<any[]>([]);
  const [makes, setMakes] = useState<any[]>([]);
  const [transmissions, setTransmissions] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<any>({
    bodyStyle: {},
    make: {},
    model: {},
    transmation: {},
  });
  const [popuplarBrands, setPopuplarBrands] = useState<PouplarBrandsProps[]>([
    { image: Images.MB, name: "Mercedes Benz", id: 240 },
    {
      image: Images.VW,
      name: "volkswagen",
      id: 359,
    },
    { image: Images.BMW, name: "BMW", id: 48 },
    { image: Images.Audi, name: "Audi", id: 30 },
    { image: Images.Porsche, name: "Porsche", id: 280 },
    { image: Images.Ford, name: "Ford", id: 125 },
    { image: Images.Ferrari, name: "Ferrari", id: 119 },
    { image: Images.Lamborghini, name: "Lamborghini", id: 203 },
    { image: Images.AstonMartin, name: "Aston Martin", id: 27 },
    { image: Images.McLaren, name: "McLaren", id: 237 },
    { image: Images.LandRover, name: "Land Rover", id: 205 },
  ]);

  useEffect(
    () =>
      setBodyStyles([
        {
          label: t("filter_options.body_style_options.all"),
          value: "",
        },
        {
          label: t("filter_options.body_style_options.coupe"),
          value: "coupe",
        },
        {
          label: t("filter_options.body_style_options.convertible"),
          value: "convertible",
        },
        {
          label: t("filter_options.body_style_options.hatchback"),
          value: "hatchback",
        },
        {
          label: t("filter_options.body_style_options.sedan"),
          value: "sedan",
        },
        {
          label: "filter_options.body_style_options.suv/crossover",
          value: "suv/crossover",
        },
        {
          label: t("filter_options.body_style_options.truck"),
          value: "truck",
        },
        {
          label: t("filter_options.body_style_options.van/minivan"),
          value: "van/minivan",
        },
        {
          label: t("filter_options.body_style_options.wagon"),
          value: "wagon",
        },
      ]),
    []
  );

  useEffect(
    () =>
      setTransmissions([
        {
          label: t("filter_options.transmission_options.all"),
          value: "",
        },
        {
          label: t("filter_options.transmission_options.automatic"),
          value: "automatic",
        },
        {
          label: t("filter_options.transmission_options.manual"),
          value: "manual",
        },
      ]),
    []
  );

  useEffect(() => setBrands(Brands), [Brands]);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleFilters = (object: string, key: string, value: any) => {
    // if value is undefined then reset the empty string
    !value && (value = "");
    // temp variable
    let newFilters: any = { ...filters };
    // update the value in the temp object
    newFilters[object] = {
      ...newFilters[object],
      [key]:
        key == "value"
          ? object === "make"
            ? value?.name?.toLowerCase() ?? ""
            : object === "model"
              ? value?.model?.toLowerCase() ?? ""
              : object === "bodyStyle" || object === "transmation"
                ? value?.value?.toLowerCase() ?? ""
                : value ?? ""
          : value ?? "",
    };
    // if user want to change the make then reset the selected model and setModels of the selected make
    if (object === "make") {
      // set model to empty string
      newFilters['model'] = {
        open: false,
        value: "",
      };
      // temp variables to store data
      let new_models: any[] = [], models_name: any = [];
      // store model names in variable
      makes?.length > 0 && makes?.map(item => item?.make.toLowerCase() == value?.name?.toLowerCase() && (models_name = item?.models)
      )
      // convert the model format into {model: model_name} and restore into another variable for autocomplete
      Array.isArray(models_name) && models_name?.length > 0 && models_name?.map((item: any) => new_models.push({ model: item }))
      setModels(new_models)
    }
    // set new filter values
    setFilters({ ...newFilters });
    key == "value" &&
      setFilterValue((old: any) => {
        return { ...old, [object]: value };
      });
  };

  const handleApplyFilter = () => {
    applyFilters();
    setOpen(!open);
  };

  const handleYears = () => {
    const array: number[] = [];
    const StartYear = 1981;
    const CurrentYear = new Date().getFullYear();
    const CalculateYearlyDifference = CurrentYear - StartYear;
    for (let i = 0; i <= CalculateYearlyDifference; i++) {
      array.push(StartYear + i);
    }
    setYears(array);
    setFilters({
      ...filters,
      year: { open: false, value: [StartYear, CurrentYear] },
    });
    // setValue([StartYear, CurrentYear]);
  };

  useEffect(() => {
    handleYears();
  }, []);

  const style = {
    color: "var(--dark-color)",
    borderRadius: "50%",
  };

  const getMakes = () => {
    Services.Listing.GetMakes().then((res) =>
      res.status && setMakes(res.data)
    );
  };

  useEffect(() => getMakes(), []);

  const getModels = () => {
    Services.Listing.GetModels().then((res) =>
      res.status && setModels(res.data)
    );
  };

  useEffect(() => getModels(), []);

  return (
    <div>
      <ThemeProvider theme={themes.ButtonActiveTheme}>
        <Button
          variant="contained"
          onClick={toggleDrawer(true)}
          sx={{ textTransform: "capitalize", minWidth: "max-content" }}
        >
          <Tune className="me-1 sm:me-2" /> {t("filter_butn_text")}
        </Button>
      </ThemeProvider>
      <React.Fragment key={openSide}>
        <JoyDrawer
          open={open}
          onClose={toggleDrawer(false)}
          anchor={openSide}
          sx={themes.JoyUiDrawer}
        >
          <div>
            <div className="flex flex-col gap-4 py-12 px-6">
              {/* title */}
              <div className="flex gap-1 justify-start items-center dark:text-white">
                <Tune /> <h3 className="p00">{t("fitler_title")}</h3>
              </div>
              {/* year */}
              <div className="pt-5">
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    handleFilters("year", "open", !filters?.year?.open)
                  }
                >
                  <p className="filterStyle">{t("filter_options.year")}</p>
                  <div>
                    {!filters?.year?.open ? (
                      <AddCircleOutline
                        fontSize="medium"
                        sx={style}
                        className="cursor-pointer"
                      />
                    ) : (
                      <RemoveCircleOutline
                        fontSize="medium"
                        className="cursor-pointer dark:text-white"
                      />
                    )}
                  </div>
                </div>
                {filters?.year?.open && (
                  <div className="px-4">
                    <ThemeProvider theme={themes.MuiSliderTheme}>
                      <Slider
                        getAriaLabel={() => t("filter_options.year")}
                        value={filters?.year?.value}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        min={years?.length > 0 ? Number(years[0]) : 0}
                        max={
                          years?.length > 1
                            ? Number(years[years?.length - 1])
                            : years?.length > 0
                              ? Number(years[0])
                              : 0
                        }
                        onChange={(
                          event: Event,
                          newValue: number | number[],
                          activeThumb: number
                        ) => {
                          if (!Array.isArray(newValue)) {
                            return;
                          }
                          handleFilters(
                            "year",
                            "value",
                            activeThumb === 0
                              ? [
                                Math.min(
                                  newValue[0],
                                  filters.year.value[1] - minDistance
                                ),
                                filters.year.value[1],
                              ]
                              : [
                                filters.year.value[0],
                                Math.max(
                                  newValue[1],
                                  filters.year.value[0] + minDistance
                                ),
                              ]
                          );
                        }}
                      />
                    </ThemeProvider>
                  </div>
                )}
              </div>
              {/* transmission */}
              <div>
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    handleFilters(
                      "transmation",
                      "open",
                      !filters?.transmation?.open
                    )
                  }
                >
                  <p className="filterStyle">
                    {t("filter_options.transmission_options.label")}
                  </p>
                  <div>
                    {!filters?.transmation?.open ? (
                      <AddCircleOutline
                        fontSize="medium"
                        className="cursor-pointer"
                        sx={style}
                      />
                    ) : (
                      <RemoveCircleOutline
                        fontSize="medium"
                        className="cursor-pointer dark:text-white"
                      />
                    )}
                  </div>
                </div>
                {filters?.transmation?.open && (
                  <div className="pt-4">
                    <Autocomplete
                      id="transmation"
                      options={transmissions}
                      sx={themes.JoyGrayShadeInputStyle2}
                      value={filterValue?.transmation?.label}
                      placeholder="Select transmation"
                      onChange={(e: any, newValue: any) =>
                        handleFilters("transmation", "value", newValue)
                      }
                    />
                  </div>
                )}
              </div>
              {/* body style */}
              <div>
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    handleFilters(
                      "bodyStyle",
                      "open",
                      !filters?.bodyStyle?.open
                    )
                  }
                >
                  <p className="filterStyle">
                    {t("filter_options.body_style_options.label")}
                  </p>
                  <div>
                    {!filters?.bodyStyle?.open ? (
                      <AddCircleOutline
                        fontSize="medium"
                        sx={style}
                        className="cursor-pointer"
                      />
                    ) : (
                      <RemoveCircleOutline
                        fontSize="medium"
                        className="cursor-pointer dark:text-white"
                      />
                    )}
                  </div>
                </div>
                {filters?.bodyStyle?.open && (
                  <div className="pt-4">
                    <Autocomplete
                      id="bodyStyle"
                      options={bodyStyles}
                      sx={themes.JoyGrayShadeInputStyle2}
                      value={filterValue?.bodyStyle?.label}
                      placeholder="Select Body Style"
                      onChange={(e: any, newValue: any) =>
                        handleFilters("bodyStyle", "value", newValue)
                      }
                    />
                  </div>
                )}
              </div>
              {/* make */}
              <div>
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    handleFilters("make", "open", !filters?.make?.open)
                  }
                >
                  <p className="filterStyle">{t("facts_info.make")}</p>
                  <div>
                    {!filters?.make?.open ? (
                      <AddCircleOutline
                        fontSize="medium"
                        sx={style}
                        className="cursor-pointer"
                      />
                    ) : (
                      <RemoveCircleOutline
                        fontSize="medium"
                        className="cursor-pointer dark:text-white"
                      />
                    )}
                  </div>
                </div>
                {filters?.make?.open && (
                  <>
                    <div className="pt-4">
                      <Autocomplete
                        id="make"
                        options={brands}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, option) => (
                          <AutocompleteOption {...props}>
                            <ListItemDecorator>
                              <img
                                loading="lazy"
                                width="30"
                                src={
                                  process.env.REACT_APP_HOST_API_KEY +
                                  option?.original
                                }
                                alt={option?.name}
                              />
                            </ListItemDecorator>
                            <ListItemContent sx={{ fontSize: "sm" }}>
                              {option?.name}
                            </ListItemContent>
                          </AutocompleteOption>
                        )}
                        sx={themes.JoyGrayShadeInputStyle2}
                        value={filterValue?.name}
                        placeholder="Select Brand"
                        onChange={(e: any, newValue: any) =>
                          handleFilters("make", "value", newValue)
                        }
                      />
                    </div>
                    <div className="pt-2">
                      <div className=" flex justify-start items-stretch flex-wrap gap-1.5">
                        {popuplarBrands?.length > 0 &&
                          popuplarBrands?.map((item) => (
                            <div
                              key={item?.id}
                              className={`w-12 h-12 border p-1 rounded bg-white dark:darkShadow ${item?.name?.toLowerCase() == filters?.make?.value?.toLowerCase() ? "border-[--dark]" : "border-[rgba(0px_0px_0px_0.1px)]"}`}
                              onClick={(e: any) =>
                                handleFilters(
                                  "make",
                                  "value",
                                  item?.name?.toLowerCase() == filters?.make?.value?.toLowerCase() ? null :
                                    (brands?.length > 0
                                      ? (brands?.filter(
                                        (b_item) => b_item?.id === item?.id
                                      )[0] ?? null)
                                      : null)
                                )
                              }
                            >
                              <Tooltip title={item?.name}>
                                <img
                                  src={item?.image}
                                  alt={item?.name}
                                  className="w-full h-full"
                                />
                              </Tooltip>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* model */}
              <div>
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    handleFilters("model", "open", !filters?.model?.open)
                  }
                >
                  <p className="filterStyle">{t("facts_info.model")}</p>
                  <div>
                    {!filters?.model?.open ? (
                      <AddCircleOutline
                        fontSize="medium"
                        sx={style}
                        className="cursor-pointer"
                      />
                    ) : (
                      <RemoveCircleOutline
                        fontSize="medium"
                        className="cursor-pointer dark:text-white"
                      />
                    )}
                  </div>
                </div>
                {filters?.model?.open && (
                  <div className="pt-4">
                    <Autocomplete
                      id="model"
                      options={models}
                      getOptionLabel={(option) => option?.model}
                      sx={themes.JoyGrayShadeInputStyle2}
                      value={filterValue?.model?.model}
                      placeholder="Select Model"
                      onChange={(e: any, newValue: any) =>
                        handleFilters("model", "value", newValue)
                      }
                    />
                  </div>
                )}
              </div>
              {/* submit butn */}
              <div className="flex justify-center mt-8">
                <ThemeProvider theme={themes.ButtonActiveTheme}>
                  <Button
                    variant="contained"
                    onClick={() => handleApplyFilter()}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {t("filter_options.apply_filter_butn_text")}{" "}
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </JoyDrawer>
      </React.Fragment>
    </div >
  );
};

export default FiltersComponent;
